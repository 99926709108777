import { Button } from '@material-ui/core'
import { SelectTableHeader } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableHeader'
import { SelectTableTbody } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableTbody'
import { SelectTableThead } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableThead'
import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import { ISelectTableProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import {
	addUnc,
	changeUncCheckedTable,
	createSpecialUnc,
	selectTableElem,
} from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const SelectTable: React.FC<ISelectTableProps> = ({ setShowDirections }) => {
	const { selectedElem, uncTableData, interpolation, originalObj, uncCheckedTable } = useSelector(
		(state: IState) => state.userProjects
	)
	const dispatch = useDispatch()
	const [checkedTable, setChecked] = useState('1')
	const [showInter, setShowInter] = useState(false)
	const tableNums: string[] = tbodyArr.filter(item => item.sitePreparation).map(item => String(item.tableNum))
	const tableTitles: any[] = tbodyArr
		.filter(item => item.sitePreparation)
		.map(item => ({ title: item.titleShort, tableNum: String(item.tableNum) }))

	const handlerAddUnc = (uncCellId: string, interpolation: number, active: number) => {
		if (uncCellId && !interpolation && active && uncCheckedTable == 6) {
			dispatch(addUnc(1, uncCellId, 0, true))
		} else if (uncCellId && !interpolation && active) {
			dispatch(addUnc(uncCheckedTable, uncCellId, 0, true))
		}
	}
	const confirmHandler = () => {
		const clone = Object.assign({}, interpolation)

		Object.keys(interpolation).forEach(key => {
			if (clone[key] === 'deleted') {
				delete clone[key]
			}
		})
		selectedElem &&
			dispatch(createSpecialUnc(selectedElem.id, originalObj.regionIds[0], clone, String(uncCheckedTable)))
	}

	useEffect(() => {
		if (selectedElem && selectedElem.code.toLowerCase() === 'с1') {
			dispatch(changeUncCheckedTable(8))
			setChecked('8')
		}
	}, [selectedElem])

	return (
		<div className={`main_table ${selectedElem ? 'main_table__show' : ''}`}>
			<div className='pannel_header'>
				<div className='pannel__nav'>
					<Button
						onClick={() => {
							dispatch(selectTableElem(null))
						}}
						className='pannel__nav-btn'
					>
						Назад
					</Button>
				</div>
				{selectedElem && (
					<>
						<div className='pannel_header-wrapper'>
							<div className='title'>
								<div>
									<span className='table_num'>{`Таблица ${selectedElem.code}. `}</span>
									{selectedElem.name}
								</div>
							</div>
							<div>
								<div className='subtitle'>{`Измеритель: ${selectedElem.unit}`}</div>
								<div onClick={() => setShowDirections(true)} className='note_btn'>
									Указания
								</div>
							</div>
						</div>
						<SelectTableHeader
							setChecked={setChecked}
							checkedTable={uncCheckedTable}
							tableNums={tableNums}
							tableTitles={tableTitles}
							selectedElem={selectedElem}
							uncTableData={uncTableData}
							setShowInter={setShowInter}
							showInter={showInter}
							createSpecialUnc={confirmHandler}
						/>
					</>
				)}
			</div>
			<div className='table_wrapper table_wrapper-add_table'>
				<table className='add_table'>
					<SelectTableThead uncTableData={uncTableData} />
					<SelectTableTbody handlerAddUnc={handlerAddUnc} uncTableData={uncTableData} />
				</table>
			</div>
		</div>
	)
}
