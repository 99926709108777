import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

const useStylesFormControl = makeStyles({
	root: {
		'& .MuiSelect-icon': {
			padding: 0,
			top: 'calc(50% - 12px)',
			color: 'rgba(0, 0, 0, 0.54)',
			right: 4,
			position: 'absolute',
			pointerEvents: 'none',
		},
	},
})

interface IAdminPanelCreateUserCompany {
	name: string
	label: string
	defaultValue?: string
	getValue: (name: string, value: string) => void
	type: string
}

const AdminPanelCreateUserCompany: FC<IAdminPanelCreateUserCompany> = ({
	name,
	label,
	getValue,
	type,
	defaultValue,
}) => {
	const classesFromControl = useStylesFormControl()
	const [value, setValue] = React.useState(defaultValue ? defaultValue : '')

	const { company } = useSelector((state: IState) => state.public)

	const handleChange = (event: any) => {
		getValue(name, event.target.value)
		setValue(event.target.value)
	}

	return (
		<FormControl fullWidth classes={classesFromControl}>
			<label style={{ marginBottom: 8, display: 'block' }}>{label}</label>
			<Select value={value} onChange={handleChange} fullWidth variant='outlined'>
				{type === 'company'
					? company?.slice().sort((a, b) => a?.name?.localeCompare(b?.name))?.map(company => (
							<MenuItem value={company.id} key={company.id}>
								{`${company.name} ${company.inn ? `, ИНН: ${company.inn}` : ''}`}
							</MenuItem>
					  ))
					: null}
			</Select>
		</FormControl>
	)
}

export default AdminPanelCreateUserCompany
