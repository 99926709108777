import { IconButton } from '@material-ui/core'
import { ModalContext } from '@root-gipro/context/modal-context'
import { SitePreparationWindow } from '@root-gipro/modules/userProjects/components/Project/Modal'
import UncAddPannel from '@root-gipro/modules/userProjects/components/Project/UncAdd/UncAddPannel'
import { TableContainer } from '@root-gipro/modules/userProjects/components/Project/UncTable/TableContainer'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'
import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import {
	copyProject,
	createUncB1,
	editPositionUnc,
	setActiveTotal,
	setUncEditMode,
} from '@root-gipro/modules/userProjects/store/actions'
import '@root-gipro/modules/userProjects/styles/Project.scss'
import { IRegion, IUnc } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { ReactText, useContext, useEffect, useMemo, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

const UncTablContainer: React.FC = () => {
	const routeMatch = useRouteMatch()
	const typePageUrl: string = routeMatch.path.split('/')[1]
	const currentState = typePageUrl === 'user-archive' ? 'userArchives' : 'userProjects'
	const [isArchived, setIsArchived] = useState<boolean>(false)
	const tableNums: string[] = tbodyArr.filter(item => item.sitePreparation).map(item => String(item.tableNum))
	const [checkedTable, setCheckedTable] = useState('1')
	const [checkedRegionId, setCheckedRegion] = useState<ReactText>()
	const { projectObj, activeUncs, uncs, activeTotal } = useSelector((state: IState) => state.userProjects)
	const [filteredRegionActiveUncs, setFilteredRegionActiveUncs] = useState<{ [key: string]: IUnc[] }>({})
	const { regions } = useSelector((state: IState) => state.public)
	const { uncEditMode } = useSelector((state: IState) => state.userProjects)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const dispatch = useDispatch()

	useEffect(() => {
		setIsArchived(typePageUrl.includes('user-archive'))
	}, [])

	const regionList: IRegion[] = useMemo(() => {
		return regions?.filter((elem: IRegion) => projectObj.regionIds.includes(elem.id))
	}, [projectObj, regions])

	useEffect(() => {
		const result: { [key: string]: any } = {}
		activeUncs?.forEach((unc: IUnc) => {
			if (result[unc.regionId]) {
				result[unc.regionId] = [...result[unc.regionId], unc]
			} else {
				result[unc.regionId] = [unc]
			}
		})
		setFilteredRegionActiveUncs(result)
	}, [activeUncs])
	useEffect(() => {
		for (const key in filteredRegionActiveUncs) {
			const costC1 = filteredRegionActiveUncs[key]
			if (costC1[0].uncMain?.code === 'С1') {
				const sumCostC1 = costC1.reduce((accum: number, curr: IUnc) => (accum += curr.totalCost), 0)
				dispatch(setActiveTotal(sumCostC1))
			} else {
				dispatch(setActiveTotal(null))
			}
			setCheckedRegion(filteredRegionActiveUncs[key][0]?.regionId)
		}
	},[filteredRegionActiveUncs])

	const onDragEnd = (params: any) => {
		const { destination } = params
		if (!destination) {
			return
		}
		if (params.destination) dispatch(editPositionUnc(params))
	}

	return (
		<>
			<div className='table_wrapper'>
				<DragDropContext onDragEnd={onDragEnd}>
					<div className='container'>
						<div className='unc_table__head'>
							<div className='unc_table__head-left'>
								<div className='head-left__title'>
									Расценки УНЦ <span className='currency'>тыс ₽</span>
								</div>
							</div>
							{!isArchived ? (
								<div className='unc_table__head-right'>
									<div className='user-projects__options'>
										<IconButton onClick={() => dispatch(copyProject(projectObj.id!, `${projectObj.code}_COPY`))}>
											<span className='copy-icon'></span>
										</IconButton>
										<IconButton onClick={() => dispatch(setUncEditMode(!uncEditMode))}>
											<span className='add-unc-icon'></span>
										</IconButton>
									</div>
								</div>
							) : null}
						</div>
					</div>

					<TableContainer uncs={uncs} sitePreparation={currentState === 'userArchives' ? false : true} />
				</DragDropContext>

				{anchorEl?.dataset.popup === cellTags[1] && (
					<SitePreparationWindow
						anchorEl={anchorEl!}
						setAnchorEl={setAnchorEl!}
						activeTotal={activeTotal!}
						regionList={regionList}
						tableNums={tableNums}
						checkedRegionId={checkedRegionId}
						setCheckedRegion={setCheckedRegion}
						checkedTable={checkedTable}
						setCheckedTable={setCheckedTable}
						handler={() => {
							dispatch(
								createUncB1(
									projectObj.unc_sort == '2024' ? 230 : 34,
									projectObj.unc_sort == '2024' ? 3396 : 58,
									checkedRegionId!,
									1,
									checkedTable,
									activeTotal! / 1000
								)
							)

							setAnchorEl!(null)
						}}
					/>
				)}
			</div>
			<UncAddPannel uncEditMode={uncEditMode} />
		</>
	)
}
export { UncTablContainer }
