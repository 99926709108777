import cn from 'classnames'
import uniqueId from 'lodash/uniqueId'
import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import Tooltip from '@material-ui/core/Tooltip'
import { Cells } from '@root-gipro/modules/userProjects/components/Project/UncTable/Cells'
import {
	BodyType,
	gridTemplateColumnsAreaCalculation,
	theadCAreaCalculation,
} from '@root-gipro/modules/userProjects/models/consts/unc-thead.const'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import { IUnc } from '@root-gipro/store/interfaces'

interface RowCellsProps {
	title: string
	uncs: IUnc[]
	uncsFiltered: IUnc[]
	tableNum: number
	currentCellTags: string
	voltageRef: MutableRefObject<undefined | string>
	isArchived?: boolean
	checkedTable: number
	currentUncSort: string | null
}

export const RowCells: FC<RowCellsProps> = ({
	voltageRef,
	uncs,
	uncsFiltered,
	tableNum,
	currentCellTags,
	isArchived,
	checkedTable,
	currentUncSort,
}) => {
	const [uncSorted, setUncSorted] = useState<IUnc[]>([])

	useEffect(() => {
		if (uncsFiltered.length) {
			setUncSorted(uncsFiltered)
		} else if (uncs.length) {
			setUncSorted(uncs)
		}
	}, [uncsFiltered, uncs])

	return (
		<>
			{tableNum === 8 && uncs.length > 0 && (
				<div
					style={{ gridTemplateColumns: gridTemplateColumnsAreaCalculation, gap: '8px' }}
					className={cn(styles.thead, styles.grid)}
				>
					{theadCAreaCalculation.map((item: BodyType) => (
						<Tooltip key={uniqueId('unc_')} title={item.tooltip} enterDelay={500} enterNextDelay={500} placement='top'>
							<div className={cn(styles.theadCell)}>{item.title}</div>
						</Tooltip>
					))}
				</div>
			)}

			<Droppable droppableId={`tbody ${tableNum && tableNum}`}>
				{(provided, snapshot) => {
					return uncsFiltered.length > 0 ? (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{uncsFiltered
								.filter(unc => {
									//если выбраны все расценки
									if (checkedTable === 6) {
										return unc
									} else {
										return unc.tableNum === checkedTable
									}
								})
								.map((elem, ind, arr) => (
									<Cells
										uncs={arr}
										index={ind}
										key={elem.id}
										unc={elem}
										currentUncSort={currentUncSort ? currentUncSort : null}
										currentCellTags={currentCellTags}
										voltageRef={voltageRef}
										tableNum={tableNum}
										isArchived={isArchived}
										checkedTable={checkedTable}
									/>
								))}
							<div className={cn(styles.providePlaceHold)}>{provided.placeholder} </div>
						</div>
					) : (
						<div ref={provided.innerRef} {...provided.droppableProps} className={styles.nothing}>
							Нет данных
						</div>
					)
				}}
			</Droppable>
		</>
	)
}
