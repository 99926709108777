import React from 'react'
import ScrollArea from 'react-scrollbar'

import { Backdrop, Modal } from '@material-ui/core/'
import { DirectionsProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'

const Directions: React.FC<DirectionsProps> = ({ text, closeHandler, showDirections }) => {
	const { projectObj } = useSelector((state: IState) => state.userProjects)
	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={showDirections}
			onClose={closeHandler}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			disablePortal
			disableEnforceFocus
			disableAutoFocus
		>
			<div className='directions_wrapper'>
				<div className='directions'>
					<div className='directions_header'>
						<h3 className='title'>
							{projectObj.unc_sort && projectObj.unc_sort == '2018'
								? 'Указания к таблице Приказа Минэнерго от 17.01.2019 №10'
								: 'Указания к таблице Приказа Минэнерго'}
						</h3>
						<div onClick={closeHandler} className='close_btn'>
							Закрыть
						</div>
					</div>
					<ScrollArea speed={0.8} vertical={true} style={{ maxHeight: 440 }} smoothScrolling={true}>
						<div className='text'>
							{text.split('\\n').map((el, i) => {
								return <p key={i}>{el}</p>
							})}
						</div>
					</ScrollArea>
				</div>
			</div>
		</Modal>
	)
}

export default Directions
