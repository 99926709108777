import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Directions from './Directions'
import {
	setUncEditMode,
	clearBuffer,
	setFilter,
	deleteUnc,
	setProjectUncs,
	selectTableElem,
} from '@root-gipro/modules/userProjects/store/actions'
import { fetchUserProject } from '@root-gipro/modules/userProjects/store/actions'
import { UncList } from '@root-gipro/modules/userProjects/components/Project/UncAdd/UncList'
import { RightTableUnc } from '@root-gipro/modules/userProjects/components/Project/UncAdd/RightTableUnc'
import { SelectTable } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTable'
import { IState } from '@root-gipro/store/interfaces/state'
import { IUncAddPannelProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { Modal } from '@material-ui/core/'
import { Backdrop } from '@material-ui/core/'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import '@root-gipro/modules/userProjects/styles/UncAdd.scss'

const UncAddPannel: React.FC<IUncAddPannelProps> = ({ uncEditMode }) => {
	const [showDirections, setShowDirections] = useState(false)

	const { uncTableData, uncAddBuffer, uncs, projectObj } = useSelector((state: IState) => state.userProjects)
	const dispatch = useDispatch()

	const cancelHandler = () => {
		dispatch(selectTableElem(null))
		uncAddBuffer.forEach((elem: any) => dispatch(deleteUnc(elem.id, true)))
		dispatch(setFilter(''))
		dispatch(setUncEditMode(false))
	}
	const readyHandler = () => {
		let clone = uncs.slice()
		clone = clone.concat(uncAddBuffer)
		dispatch(setProjectUncs(clone))
		if (uncAddBuffer.length > 0) dispatch(fetchUserProject(projectObj.id!))
		dispatch(clearBuffer())
		dispatch(selectTableElem(null))
		dispatch(setFilter(''))
		dispatch(setUncEditMode(false))
	}

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={uncEditMode}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<div className='unc_add_wrapper'>
				<Directions
					showDirections={showDirections}
					text={uncTableData?.note ? uncTableData.note : ''}
					closeHandler={() => setShowDirections(false)}
				/>
				<div className='scroll-wrapper'>
					<div className='unc_add unc_add_container' style={{ filter: showDirections ? 'blur(4px)' : '' }}>
						<UncList />
						<div className='wrapper-select-table'>
							<SelectTable setShowDirections={(show: boolean) => setShowDirections(show)} />
						</div>
						<div className='aside'>
							<div className='aside_top'>
								<div>Переместите в</div>
								<RightTableUnc uncAddBuffer={uncAddBuffer} deleteUnc={id => dispatch(deleteUnc(id, true))} />
							</div>
							<div className='action_btns mt-8 aside_bottom'>
								<PrimaryBtn variant='contained' onClick={readyHandler}>
									Готово
								</PrimaryBtn>
								<SecondaryBtn variant='contained' onClick={cancelHandler} className='rej_btn'>
									Отмена
								</SecondaryBtn>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
export default UncAddPannel
