import { ReactText } from 'react'
import { IUnc, IUncTableElem, IUserProject, IT7Data, IUncCell } from '@root-gipro/store/interfaces'
import { UserProjectsType } from '@root-gipro/modules/userProjects/models/interfaces/user-project.actions'

export const AddNewVersion = (data: IUserProject, versionName: string, currentUrl: string, uncSort?: string) => ({
	type: UserProjectsType.ADD_NEW_VERSION,
	data,
	versionName,
	currentUrl,
	uncSort,
})
export const changeVersionName = (versionName: string) => ({
	type: UserProjectsType.СHANGE_VERSION_NAME,
	versionName,
})
export const fetchRequest = () => ({
	type: UserProjectsType.FETCH_REQUEST,
})
export const createProjectTemplate = () => ({
	type: UserProjectsType.CREATE_PROJECT_TEMPLATE,
})

export const fetchUserProject = (id: string) => ({
	type: UserProjectsType.FETCH_USER_PROJECT,
	id,
})

export const setExportable = (exportable: boolean) => ({
	type: UserProjectsType.SET_EXPORTABLE,
	exportable,
})
export const changeUncCheckedTable = (uncCheckedTable: number) => ({
	type: UserProjectsType.CHANGE_UNC_CHECKED_TABLE,
	uncCheckedTable,
})

export const saveUserProject = (body: IUserProject, isNew: boolean, id?: ReactText, showAlert?: boolean) => ({
	type: UserProjectsType.SAVE_USER_PROJECT,
	body,
	isNew,
	id,
	showAlert,
})

export const updateStatusUserProject = (locked: boolean, id?: string) => ({
	type: UserProjectsType.UPDATE_STATUS_USER_PROJECT,
	locked,
	id,
})

export const deleteUserProject = (id: string, name: string) => ({
	type: UserProjectsType.DELETE_USER_PROJECT,
	id,
	name,
})

export const chooseProject = (project: IUserProject) => ({
	type: UserProjectsType.CHOOSE_PROJECT,
	project,
})

export const setOriginal = (project: IUserProject) => ({
	type: UserProjectsType.SET_ORIGINAL_OBJ,
	project,
})

export const setToggleTabs = (show: boolean) => ({
	type: UserProjectsType.SET_TOGGLE_TABS,
	show,
})

export const setUncEditMode = (mode: boolean) => ({
	type: UserProjectsType.SET_UNC_EDIT_MODE,
	mode,
})

export const setT7EditMode = (mode: boolean) => ({
	type: UserProjectsType.SET_T7_EDIT_MODE,
	mode,
})

export const setActiveUncs = (uncs: IUnc[]) => ({
	type: UserProjectsType.SET_ACTIVE_UNCS,
	uncs,
})

export const getProjects = () => ({
	type: UserProjectsType.GET_USER_PROJECTS,
})

// used SelectedContainer in common-project
export const fetchUncs = (id: string) => ({
	type: UserProjectsType.FETCH_UNCS,
	id,
})
export const getT7Data = (id: string, version: number) => ({
	type: UserProjectsType.GET_T7_DATA,
	id,
	version,
})

export const setT7Data = (t7Data: IT7Data) => ({
	type: UserProjectsType.SET_T7_DATA,
	t7Data,
})

export const setT7Version = (t7Version: number) => ({
	type: UserProjectsType.SET_T7_VERSION,
	t7Version,
})

export const editPositionUnc = (params: any) => {
	return {
		type: UserProjectsType.UNC_POSITION_EDIT,
		params,
	}
}
export const setProjectUncs = (uncs: IUnc[]) => ({
	type: UserProjectsType.SET_PROJECT_UNCS,
	uncs,
})

export const setIsNewProject = (isNew: boolean) => ({
	type: UserProjectsType.SET_IS_NEW_PROJECT,
	isNew,
})

export const setProjectCode = (code: string) => ({
	type: UserProjectsType.SET_PROJECT_СODE,
	code,
})

export const setProjectName = (name: string) => ({
	type: UserProjectsType.SET_PROJECT_NAME,
	name,
})

export const setProjectYears = (begin: ReactText, end: ReactText) => ({
	type: UserProjectsType.SET_YEARS,
	begin,
	end,
})

export const setProjectRegions = (regionIds: string[]) => ({
	type: UserProjectsType.SET_REGION_IDS,
	regionIds,
})
export const setProjectFilial = (filialId: string) => ({
	type: UserProjectsType.SET_FILIAL_IDS,
	filialId,
})

export const setProjectValuation = (valuation: number) => ({
	type: UserProjectsType.SET_VALUATION_OFFER_FORECAST,
	valuation,
})

export const setProjectFinancialNeeds = (financial_needs_withNds: number) => ({
	type: UserProjectsType.SET_FINANCIAL_NEEDS,
	financial_needs_withNds,
})

export const setProjectAdditional = (offer: number) => ({
	type: UserProjectsType.SET_ADDITIONAL_FUNDING_OFFER,
	offer,
})

export const setProjectRationale = (offerRationale: string) => ({
	type: UserProjectsType.SET_OFFER_RATIONALE,
	offerRationale,
})

export const setProjectActual = (actualFunding: number) => ({
	type: UserProjectsType.SET_ACTUAL_FUNDING,
	actualFunding,
})

export const setProjectNds = (nds: number) => ({
	type: UserProjectsType.SET_NDS,
	nds,
})

export const setProjectGroupNum = (groupNum: string) => ({
	type: UserProjectsType.SET_GROUP_TYPE,
	groupNum,
})

export const setProjectPhase = (phase: string) => ({
	type: UserProjectsType.SET_PHASE,
	phase,
})

export const setProjectDecreeName = (decreeName: string) => ({
	type: UserProjectsType.SET_DECREE_NAME,
	decreeName,
})

export const setProjectDecreeNumber = (decreeNumber: string) => ({
	type: UserProjectsType.SET_DECREE_NUM,
	decreeNumber,
})

export const setProjectExceeding = (notExceeding: number | null) => ({
	type: UserProjectsType.SET_NOT_EXCEED,
	notExceeding,
})

export const fetchUncTableData = (id: number, unc_sort: string) => ({
	type: UserProjectsType.FETCH_UNC_TABLE_DATA,
	id,
	unc_sort,
})

export const selectTableElem = (selectedElem: IUncTableElem | null) => ({
	type: UserProjectsType.SELECT_TABLE_ELEM,
	selectedElem,
})

export const changeComment = (
	id: ReactText,
	objectName: string,
	stage: string,
	kf: number,
	description: string,
	comment: string,
	complicating_params: any
) => ({
	type: UserProjectsType.CHANGE_UNC_COMMENT,
	id,
	objectName,
	stage,
	kf,
	description,
	comment,
	complicating_params
})

export const changeCfArchitecture = (
	id: ReactText,
	complicating_params: any
) => ({
	type: UserProjectsType.CHANGE_UNC_CF_ARCHITECTURE,
	id,
	complicating_params
})


export const changeCounter = (id: ReactText, count: string, inBuffer?: boolean | undefined) => ({
	type: UserProjectsType.CHANGE_UNC_COUNTER,
	id,
	count,
	inBuffer,
})

export const changeRegion = (id: ReactText, regionId: ReactText, zoneId: ReactText) => ({
	type: UserProjectsType.CHANGE_UNC_REGION,
	id,
	regionId,
	zoneId,
})

export const deleteUnc = (id: ReactText, inBuffer?: boolean) => ({
	type: UserProjectsType.DELETE_UNC,
	id,
	inBuffer,
})
export const getAnalogUnc = (id: ReactText) => ({
	type: UserProjectsType.GET_ANALOG_UNC,
	id,
})

export const clearBuffer = () => ({
	type: UserProjectsType.SET_UNC_ADD_BUFFER,
	buffer: [],
})

export const changeVoltage = (id: string, voltage: number, inBuffer?: boolean) => ({
	type: UserProjectsType.CHANGE_UNC_VOLTAGE,
	id,
	voltage,
	inBuffer,
})
/// используется в разных компонентах
export const setFilter = (filter: string) => ({
	type: UserProjectsType.SET_UNC_FILTER,
	filter,
})

export const updateDeflators = (start: ReactText, end: ReactText) => ({
	type: UserProjectsType.UPDATE_DEFLATORS,
	start,
	end,
})

export const updateDeflatorsValue = (values: any) => ({
	type: UserProjectsType.UPDATE_DEFLATORS_VALUE,
	values,
})

export const setYears = (years: {
	[key: string]: {
		offer: number | null
		deflator: number | null
	}
}) => ({
	type: UserProjectsType.SET_PROJECT_YEARS,
	years,
})

export const setInterpolation = (obj: { [key: string]: ReactText }) => ({
	type: UserProjectsType.SET_INTERPOLATION,
	obj,
})

export const createSpecialUnc = (
	uncMainId: ReactText,
	regionId: ReactText,
	interpolated: { [key: string]: ReactText },
	tableNum: ReactText
) => ({
	type: UserProjectsType.CREATE_SPECIAL_UNC,
	uncMainId,
	regionId,
	interpolated,
	tableNum,
})
export const addUnc = (tableNum: ReactText, uncCellId: ReactText, count: number, addBuffer: boolean) => ({
	type: UserProjectsType.ADD_UNC,
	tableNum,
	uncCellId,
	count,
	addBuffer,
})
export const replaceUnc = (oldUncId: string, newUncId: string) => ({
	type: UserProjectsType.REPLACE_UNC,
	oldUncId,
	newUncId,
})

export const setAnalogUncList = (uncCells: IUncCell) => ({
	type: UserProjectsType.SET_ANALOG_UNC,
	uncCells,
})
export const setSelectedSectionIds = (selectedSectionIds: number[]) => ({
	type: UserProjectsType.SET_SELECTED_SECTION_IDS,
	selectedSectionIds,
})

export const setProjectFilter = (filter: string) => ({
	type: UserProjectsType.SET_PROJECT_FILTER,
	filter,
})

export const getCheckedErrors = (id: ReactText | undefined, sectionIdsArr: number[]) => ({
	type: UserProjectsType.GET_CHECKED_ERRORS,
	id,
	sectionIdsArr,
})
export const autoAddUnc = (id: ReactText | undefined, sectionIdsArr: number[]) => ({
	type: UserProjectsType.AUTO_ADD_UNC,
	id,
	sectionIdsArr,
})

export const setProjectErrors = (errors: { [key: string]: string[] } | null) => ({
	type: UserProjectsType.SET_PROJECT_ERRORS,
	errors,
})
export const setMassErrors = (
	errors: { userProjectCode: string; messages: { [key: string]: string[] }; id: string }[] | null
) => ({
	type: UserProjectsType.SET_MASS_ERRORS,
	errors,
})

export const showProjectErrors = (show: boolean) => ({
	type: UserProjectsType.SHOW_PROJECT_ERRORS,
	show,
})

export const selectVoltage = (uncCellId: ReactText, uncId: ReactText) => ({
	type: UserProjectsType.SELECT_VOLTAGE,
	uncCellId,
	uncId,
})

export const selectVoltageId = (targetVoltageId: ReactText) => ({
	type: UserProjectsType.SELECT_VOLTAGE_ID,
	targetVoltageId,
})

export const setUserProjects = (
	projects: IUserProject[],
	totalInvestmentUncOfferSum: number,
	userProjectsCnt: number
) => ({
	type: UserProjectsType.SET_USER_PROJECTS,
	projects,
	totalInvestmentUncOfferSum,
	userProjectsCnt,
})
export const addUserProjects = (projects: IUserProject[]) => ({
	type: UserProjectsType.ADD_USER_PROJECTS,
	projects,
})

export const setAllowMoreUserProjects = (allowMoreUserProjects: boolean) => ({
	type: UserProjectsType.SET_ALLOW_MORE_USER_PROJECTS,
	allowMoreUserProjects,
})

export const loadingUserProjects = (loading: boolean) => ({
	type: UserProjectsType.LOADING_USER_PROJECTS,
	loading,
})
export const loadingUpdateCalc = (loading: boolean) => ({
	type: UserProjectsType.LOADING_UPDATE_CALC,
	loading,
})

export const loadingT7Data = (loading: boolean) => ({
	type: UserProjectsType.LOADING_T7DATA,
	loading,
})

export const appendUserProjects = (projectsCount: number) => ({
	type: UserProjectsType.APPEND_USER_PROJECTS,
	projectsCount,
})

export const setUserProjectsPaginationCount = (paginationCounter: number) => ({
	type: UserProjectsType.SET_USER_PROJECTS_COUNT,
	paginationCounter,
})

export const setVoltage = (targetVoltageValues: ReactText[]) => ({
	type: UserProjectsType.SET_VOLTAGE,
	targetVoltageValues,
})

export const downloadRulesCheck = () => ({
	type: UserProjectsType.DOWNLOAD_RULES_CHECK,
})

export const uploaderRulesCheck = (file: any) => ({
	type: UserProjectsType.UPLOADER_RULES_CHECK,
	file,
})

export const showLoader = (showLoader: boolean) => ({
	type: UserProjectsType.SHOW_LOADER,
	showLoader,
})

export const setTechConnect = (techConnect: boolean) => ({
	type: UserProjectsType.TECH_CONNECT,
	techConnect,
})
export const setOnlyPIR = (onlyPIR: boolean) => ({
	type: UserProjectsType.ONLY_PIR,
	onlyPIR,
})
export const setLocked = (locked: boolean) => ({
	type: UserProjectsType.LOCKED,
	locked,
})
export const SetLockedInList = (locked: boolean) => ({
	type: UserProjectsType.SET_LOCKED_IN_LIST,
	locked,
})

export const setUserProjectInList = (project: IUserProject) => ({
	type: UserProjectsType.SET_USER_PROJECT_IN_LIST,
	project,
})

export const uploadForm20 = (file: any, importInto?: string, uncSort?: string) => ({
	type: UserProjectsType.UPLOAD_F20,
	file,
	importInto,
	uncSort
})

export const downloadForm20 = (id: ReactText | null, name: string | null, version: string, formYear?: ReactText) => ({
	type: UserProjectsType.DOWNLOAD_F20,
	id,
	name,
	version,
	formYear,
})

export const downloadForm20async = () => ({
	type: UserProjectsType.DOWNLOAD_F20_ASYNC,
})

export const downloadCheckWarns = (sectionIdsArr: number[]) => ({
	type: UserProjectsType.DOWNLOAD_CHECK_WARNS,
	sectionIdsArr,
})

export const setF20Task = (task: string) => ({
	type: UserProjectsType.SET_F20_TASK,
	task,
})

export const checkF20link = (task: string) => ({
	type: UserProjectsType.CHECK_F20_LINK,
	task,
})

export const massCheck = (sectionIdsArr: number[]) => ({
	type: UserProjectsType.MASS_CHECK,
	sectionIdsArr,
})

export const uploadForm2 = (file: any, importInto?: string, uncSort?: string) => ({
	type: UserProjectsType.UPLOAD_F2,
	file,
	importInto,
	uncSort
})

export const downloadForm2 = (id: ReactText | null, name: string | null) => ({
	type: UserProjectsType.DOWNLOAD_F2,
	id,
	name,
})

export const uploadSvod = (file: any) => ({
	type: UserProjectsType.UPLOAD_SVOD,
	file,
})

export const downloadSvod = (id: ReactText | null, name: string | null) => ({
	type: UserProjectsType.DOWNLOAD_SVOD,
	id,
	name,
})

export const copyProject = (userProjectId: ReactText, code: ReactText) => ({
	type: UserProjectsType.COPY_PROJECT,
	userProjectId,
	code,
})

export const saveT7Data = (t7Data: IT7Data, userProjectId: ReactText, version: number) => ({
	type: UserProjectsType.SAVE_T7_DATA,
	t7Data,
	userProjectId,
	version,
})

export const addPrivateProject = (projectId: ReactText) => ({
	type: UserProjectsType.ADD_PRIVATE_PROJECT,
	projectId,
})

export const getTplUnnormalizedСosts = (id: ReactText) => ({
	type: UserProjectsType.GET_TEMPLATE_UNNORMOLIZED_COSTS,
	id,
})

export const getUnnormalizedСosts = (id: ReactText) => ({
	type: UserProjectsType.GET_UNNORMOLIZED_COSTS,
	id,
})

export const uploaderUnnormalizedСosts = (file: any, id: ReactText) => ({
	type: UserProjectsType.UPLOAD_UNNORMOLIZED_COSTS,
	file,
	id,
})

export const deleteUnnormalizedСosts = (id: ReactText) => ({
	type: UserProjectsType.DELETE_UNNORMOLIZED_COSTS,
	id,
})

export const sortUserProjects = (name: string, sortName: string, order: boolean) => ({
	type: UserProjectsType.SORT_USER_PROJECTS,
	name,
	sortName,
	order,
})

export const createUncsB1 = (
	uncMainId: number,
	uncs: { [key: string]: IUnc[] },
	interpolated: number,
	tableNum: ReactText
) => ({
	type: UserProjectsType.CREATE_UNCS_B1,
	uncMainId,
	uncs,
	interpolated,
	tableNum,
})
export const getDeflators = () => ({
	type: UserProjectsType.GET_DEFLATORS,
})

export const setDeflators = (values: { year: number; value: string }[], precision: number) => ({
	type: UserProjectsType.GET_DEFLATORS,
	values,
	precision,
})

export const updateCalc = (params: any) => ({
	type: UserProjectsType.UPDATE_CALC,
	params,
})
export const setUncSort = (unc_sort: string) => ({
	type: UserProjectsType.SET_UNC_SORT,
	unc_sort,
})
export const getNonStandardizedCosts = () => ({
	type: UserProjectsType.GET_NON_STANDARDIZED_COSTS,
})
export const setNonStandardizedCosts = (non_standardized_costs: any) => ({
	type: UserProjectsType.SET_NON_STANDARDIZED_COSTS,
	non_standardized_costs,
})

export const createUncB1 = (
	uncMainId: number,
	uncCellId: number,
	regionId: ReactText,
	interpolated: number,
	tableNum: ReactText,
	count: number
) => ({
	type: UserProjectsType.CREATE_UNC_B1,
	uncMainId,
	regionId,
	interpolated,
	tableNum,
	count,
})

export const setActiveTotal = (activeTotal: number | null) => ({
	type: UserProjectsType.SET_ACTIVE_TOTAL,
	activeTotal,
})

export const setLoadingStates = (itemKey: string, isLoading: boolean) => ({
	type: UserProjectsType.SET_LOADING_STATE,
	payload: { itemKey, isLoading },
})

export const setDataImportMagazine = (data: any, link: string) => ({
	type: UserProjectsType.SET_DATA_IMPORT_MAGAZINE,
	data,
	link
})

export const setOpenModalImportMagazine = (open: boolean) => ({
	type: UserProjectsType.SET_MODAL_OPEN_IMPORT_MAGAZINE,
	open,
})

export const downloadImportData = (linked: any) => ({
	type: UserProjectsType.SET_DOWNLOAD_IMPORT_DATA,
	linked,
})