import React, { FC, useState } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { useDispatch } from 'react-redux'
import { downloadCheckWarns, uploadForm2, uploadForm20 } from '@root-gipro/modules/userProjects/store/actions'
import { Radio } from '@material-ui/core'
import styled from 'styled-components'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'

interface IDataMagazine {
	anchorEl: (EventTarget & HTMLElement) | null | undefined
	setAnchorEl(event: HTMLElement): void
	data: any
	title?: string
	startLoading: (mode: string) => void
	typeImport: string
}

const ContainerModal = styled.div`
`

const TitleChoice = styled.h2`
	margin: 35px auto;
	display: flex;
	justify-content: left;
`
const ContainerRadio = styled.div`
	margin: 20px;
`
const NameRadio = styled.span`
	margin-left: 15px;
	font-size: 18px;
	vertical-align: middle;
	cursor: pointer;
`
const ContainerButton = styled.div`
	position: relative;
	height: 20px;
`

const PositionButton = styled.div`
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100%;
	top: 10px;
`

const LabelButtonAdd = styled.label`
	color: #fff;
	width: 100px;
	border: 1px solid #3f4f5e;
	cursor: pointer;
	height: 32px;
	display: flex;
	justify-content: center;
	padding: 0 8px;
	font-size: 13px;
	min-width: 100px;
	background-color: #3f4f5e;
	text-align: center;
	border-radius: 4px;
	align-items: center;
	&:hover {
		color: rgba(0, 0, 0, 0.87);
		background-color: #f7f7f7;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
			box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}
`

const SelectChoiceImport: FC<IDataMagazine> = ({ anchorEl, setAnchorEl, data, title, startLoading, typeImport }) => {
	const dispatch = useDispatch()
	const [formVersion, setFormVersion] = useState('2024')
	const [importLocation, setImportLocation] = useState('calculations')
	const [keyImport, setKeyImport] = useState<number>(0)

	const handleFileChange = (e: any) => {
		if (typeImport === 'import-form-2') {
			dispatch(uploadForm2(e.target.files, importLocation, formVersion))
		} else if (typeImport === 'import-form-20') {
			dispatch(uploadForm20(e.target.files, importLocation, formVersion))
		}
		setKeyImport(keyImport + 1)
		setAnchorEl(null!)
		startLoading('import')
	}

	return (
		<Modal setAnchorEl={setAnchorEl} anchorEl={anchorEl} widthWindow='400px'>
			<ContainerModal>
				<div>
					<TitleChoice style={{ marginTop: '0' }}>Версия импортируемой формы</TitleChoice>
					<ContainerRadio style={{ margin: '20px' }}>
						<Radio
							checked={formVersion === '2018'}
							value='2018'
							onChange={e => setFormVersion(e.target.value)}
							color='primary'
						/>
						<NameRadio onClick={e => setFormVersion('2018')}>УНЦ-2019</NameRadio>
					</ContainerRadio>
					<ContainerRadio>
						<Radio
							value='2024'
							checked={formVersion === '2024'}
							onChange={e => setFormVersion(e.target.value)}
							color='primary'
						/>
						<NameRadio onClick={e => setFormVersion('2024')}>УНЦ-2024</NameRadio>
					</ContainerRadio>
				</div>
				<div>
					<TitleChoice>Куда импортировать форму?</TitleChoice>
					<ContainerRadio style={{ margin: '20px' }}>
						<Radio
							value='projects'
							checked={importLocation === 'projects'}
							onChange={e => setImportLocation(e.target.value)}
							color='primary'
						/>
						<NameRadio onClick={() => setImportLocation('projects')}>Проекты</NameRadio>
					</ContainerRadio>
					<ContainerRadio>
						<Radio
							value='calculations'
							checked={importLocation === 'calculations'}
							onChange={e => setImportLocation(e.target.value)}
							color='primary'
						/>
						<NameRadio onClick={() => setImportLocation('calculations')}>Расчеты</NameRadio>
					</ContainerRadio>
				</div>
				<ContainerButton>
					<PositionButton>
						<SecondaryBtn onClick={() => setAnchorEl(null!)}>Отмена</SecondaryBtn>
						<LabelButtonAdd htmlFor='file-input'>
							<input
								type='file'
								id='file-input'
								key={keyImport}
								accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
								onChange={handleFileChange}
								style={{ display: 'none' }}
							/>
							Ок
						</LabelButtonAdd>
					</PositionButton>
				</ContainerButton>
			</ContainerModal>
		</Modal>
	)
}

export default SelectChoiceImport
